<template>
  <div
    id="staff-details--card"
    class="staff-detail"
    :style="{'max-width': $vuetify.breakpoint.mdAndUp ? '450px' : 'none'}"
  >
    <div class="ml-3 mr-3">
      <v-card class="pa-1">
        <v-list-item class="pb-1">
          <v-list-item-avatar
            size="48"
            class="mr-3"
          >
            <InitialsIcon
              :given-name="staff.firstname"
              :surname="staff.lastname"
              font-class="subheading"
              size="48"
            />
          </v-list-item-avatar>
          <v-card-title class="pl-0 pt-1 pb-1">
            <div>
              <v-list-item-title class="font-weight-bold">
                {{ fullName }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ staff.staffRoleFormatted }}
              </v-list-item-subtitle>
            </div>
          </v-card-title>
        </v-list-item>
        <v-list-item>
          <v-card-text class="pl-0 pt-0">
            <div class="d-flex flex-column">
              <div
                v-if="schoolName"
                class="d-flex align-center"
              >
                <v-icon class="mr-2 ads-blue-2">
                  mdi-school-outline
                </v-icon>
                {{ schoolName }}
              </div>
              <div class="d-flex align-center">
                <v-icon class="mr-2 ads-blue-2">
                  mdi-email-outline
                </v-icon>
                <div
                  class="s-email"
                  v-if="staff.email"
                >
                  {{ staff.email }}
                </div>
                <div
                  class="s-email"
                  v-else
                >
                  Email not found
                </div>
              </div>
              <div class="d-flex align-center">
                <v-icon class="mr-2 ads-blue-2">
                  mdi-account-outline
                </v-icon>
                DET User ID: {{ staff.detuserid }}
              </div>
              <div
                class="d-flex align-center justify-space-between pt-6"
              >
                <button
                  @click="navToVerifyAccess"
                  :disabled="disableVerifyAccess"
                >
                  <span class="font-weight-bold">Verify & Repair Access</span>
                </button>
              </div>
            </div>
          </v-card-text>
        </v-list-item>
      </v-card>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.staff-detail {
  min-width: 22rem;
}
.s-email {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import {InitialsIcon} from "@nswdoe/doe-ui-core";
import {mapGetters} from 'vuex';

export default {
  props: {
    "staff": {
      type: Object,
      default: null
    },
    "disableVerifyAccess": {
        type: Boolean,
        default: false
    },
    "locationId": {
      type: Number,
      default: null
    },
    "staffId": {
      type: String,
      default: null
    }
  },
  components: {
    InitialsIcon,
  },
  data() {
    return {
      emailLoaded: false,
      loading: false
    }
  },
  computed: {
    fullName: function() {
      if (this.staff) {
        return this.staff.firstname + " " + this.staff.lastname;
      }
      return "";
    },
    schoolName: function() {
      const school = this.$store.getters.school(this.locationId);
      return school && school.name;
    },
    ...mapGetters({
      fnActionByName: 'getActionByName'
    }),
  },
  methods: {
    navToVerifyAccess() {
      this.$router.push({name: 'verifyAccess'});
    },
  }
};
</script>
