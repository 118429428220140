<template>
  <div>
    <v-container
      :class="{
        mobile: $vuetify.breakpoint.smAndDown,
        page: $vuetify.breakpoint.mdAndUp
      }"
    >
      <DesktopBackButton v-if="$vuetify.breakpoint.mdAndUp" class="mt-2" />
      <div
        v-if="loaded && staff && staff.detuserid && isAttView"
        :class="{ 'flex-column ma-3': $vuetify.breakpoint.smAndDown }"
        class="d-flex mt-3"
      >
        <v-btn
          v-if="attStatus === 'Open' && !attCompleted"
          aria-label="Confirm Attestation"
          class="primary pt-0 pb-0 pl-4 pr-4"
          @click="confirmAtt"
        >
          Confirm Attestation
        </v-btn>
        <p v-else class="pa-1">
          {{
            attStatus === "Open"
              ? `Attestation for ${fullName} is completed.`
              : "The user review window has now closed."
          }}
        </p>
      </div>
      <div
        v-if="loaded && staff && staff.detuserid"
        :class="{ 'flex-column': $vuetify.breakpoint.smAndDown }"
        class="d-flex mt-3"
      >
        <!-- Staff details card -->
        <div class="flex-shrink-1 order-md-2 ml-1">
          <StaffDetailsCard
            :location-id="locationId"
            :staff="staff"
            :staff-id="staffId"
          />
        </div>

        <!-- Apps list -->
        <div class="flex-grow-1 order-md-1">
          <div :class="{ 'mt-3': $vuetify.breakpoint.smAndDown }">
            <v-card class="msa-list">
              <div v-if="$vuetify.breakpoint.mdAndUp">
                <v-text-field
                  id="staff-apps--search-field"
                  aria-label="filter applications list"
                  class="data-table--search-field"
                  hide-details
                  label=""
                  outlined
                  placeholder="Search apps"
                  prepend-inner-icon="search"
                  @input="onSearchChangeDebounced"
                />
              </div>
              <div
                v-if="!hasApps || !fnActionByName(UIActions.LIST_APPLICATIONS)"
              >
                <div class="pl-4 pt-4 pb-2">
                  <p>No apps found.</p>
                </div>
              </div>
              <div
                v-if="hasApps && fnActionByName(UIActions.LIST_APPLICATIONS)"
              >
                <AppAccessList
                  :app-heading="'Role: ' + roleName || 'None'"
                  :apps="birthrightApps"
                  @appSelected="navigateToApplication"
                />

                <v-divider />

                <AppAccessList
                  :apps="otherApps"
                  app-heading="Other Apps"
                  @appSelected="navigateToApplication"
                />
              </div>
            </v-card>
          </div>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
import DesktopBackButton from "@/components/DesktopBackButton";
import StaffDetailsCard from "@/components/StaffDetailsCard";
import { mapGetters } from "vuex";
import searchDebounce from "@/mixins/searchDebounce";
import AppAccessList from "@/components/AppAccessList";
import { MSA_CLIENT_ERROR, UI_ACTIONS } from "@/utils/constants";
import staffApi from "@/api/staff";
import capitalise from "@/filters/capitalise";

export default {
  components: {
    DesktopBackButton,
    StaffDetailsCard,
    AppAccessList
  },
  props: {
    staffId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      UIActions: UI_ACTIONS,
      loaded: false,
      defaultBack: null,
      isExternalStaff: this.$store.getters.isExternalStaff(
        this.locationId,
        this.staffId
      )
    };
  },
  beforeDestroy() {
    this.$store.commit("setAppFilter", "");
  },
  computed: {
    ...mapGetters({
      locationId: "selectedSchoolId",
      appsChanged: "reloadApplicationsRequired",
      fnActionByName: "getActionByName",
      attStatus: "attestationWindowStatus"
    }),
    staff: function() {
      return this.$store.getters.staff(this.locationId, this.staffId);
    },
    attCompleted: function() {
      return this.$store.getters
        .staffAnnualAttestationByLocation(this.locationId)
        ?.find(staff => staff.detUserId === this.staffId)?.attestationCompleted;
    },
    fullName: function() {
      if (this.staff) {
        return this.staff.firstname + " " + this.staff.lastname;
      }
      return "";
    },
    schoolName: function() {
      const school = this.$store.getters.school(this.locationId);
      return school && school.name;
    },
    allApps: function() {
      return this.$store.getters.applications(this.locationId, this.staffId);
    },
    birthrightApps() {
      return this.allApps.filter(a => a.tasks.some(t => t.birthright));
    },
    otherApps() {
      return this.allApps.filter(a => a.tasks.every(t => !t.birthright));
    },
    hasApps() {
      return this.allApps && this.allApps.length > 0;
    },
    roleName() {
      return this.$store.getters.roleName(this.locationId, this.staffId);
    },
    isAttView() {
      return this.$router.history.current.name === "staffAppsAttestation";
    }
  },
  created: async function() {
    this.$store.commit("setAppFilter", "");

    if (this.allApps.length === 0 || this.appsChanged) {

      let locType = this.$store.state.locationsModule.locations.find(l => l.id === this.locationId).locType
      //console.log("Before calling fetchAppsForStaffAtLocation from created func: locType-" + locType);
      await this.$store.dispatch("fetchAppsForStaffAtLocation", {
        locationId: this.locationId,
        locType: locType,
        staffId: this.staffId
      });
      this.$store.commit("setReloadApplicationsRequired", false);
    }
    this.loaded = true;
    window.scroll(0, 0);
  },
  mixins: [searchDebounce],
  methods: {
    navigateToApplication(app) {
      const currentName = this.$router.history.current.name;
      const path =
        currentName === "staffApps"
          ? `${this.staffId}/application/${app.applicationCode}`
          : `application/${app.applicationCode}`;
      this.$router.push({ path: path });
    },
    async confirmAtt() {
      const netRegId = this.staff.netRegId;
      if (!netRegId) {
        return this.$store.commit("showError", {
          show: true,
          message: MSA_CLIENT_ERROR.shortNameMissing
        });
      }
      this.$store.commit("setIsLoading", true);
      try {
        const res = await staffApi.modifyStaffAnnualAttestationForLocation(
          this.locationId,
          netRegId
        );
        const newRow = res.staffAttestation;
        newRow.fullName = `${newRow.firstname} ${newRow.lastname}`;
        newRow.staffRoleFormatted = capitalise(
          (newRow.role || "")
            .toLowerCase()
            .split(".")
            .join(" ")
        );
        const staffAnnualAttestation = this.$store.state.staffModule
          .staffAnnualAttestationByLocation[this.locationId];
        staffAnnualAttestation.map(s => {
          if (s.detUserId === newRow.detUserId) {
            s.attestationCompleted = newRow.attestationCompleted;
            s.attestedBy = newRow.attestedBy;
            s.attestationDate = newRow.attestationDate;
            s.actionTaken = newRow.actionTaken;
            s.role = newRow.role;
            s.staffRoleFormatted = newRow.staffRoleFormatted;
          }
        });
        this.$store.commit("setStaffAnnualAttestation", {
          locationId: this.locationId,
          staffAnnualAttestation
        });
        this.$store.commit("showSnackbar", {
          text: `Attestation for ${newRow.detUserId} is successfully completed.`,
          type: "success",
          show: true
        });
      } catch (e) {
        throw new Error("An error occurred: " + e.message);
      } finally {
        this.$store.commit("setIsLoading", false);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.v-btn--bottom:not(.v-btn--absolute) {
  bottom: 90px;
}

.listTitle {
  line-height: 1.5rem;
}

::v-deep .v-list--two-line .v-list-item {
  min-height: 72px;
  overflow: visible;
  white-space: normal;
  height: auto;
}
</style>
