<template>
  <div v-if="apps && apps.length > 0">
    <v-list
      class="pb-0 list-header"
    >
      <v-list-item>
        <v-row>
          <v-col class="col-8 col-md-9">
            <slot name="AppHeader">
              <div>
                <span>{{ appHeading }}</span>
              </div>
            </slot>
          </v-col>
          <v-col
            class="col-4 col-md-3 pr-0"
          >
            <div v-if="$vuetify.breakpoint.mdAndUp">
              <span>{{ apps.length ? accessHeading : '' }}</span>
            </div>
          </v-col>
        </v-row>
        <v-list-item-action>
          <!-- placeholder -->
          <v-btn style="visibility: hidden">
            <v-icon>
              mdi-chevron-right
            </v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <v-list two-line>
      <div
        v-for="app in apps"
        :key="app.applicationCode"
        :id="`staff-apps--row-${app.applicationCode}`"
      >
        <v-list-item @click="navigateToApplication(app)">
          <v-row
            class="d-flex align-center"
            style="width: 100%"
          >
            <v-col class="col-8 col-md-9">
              <div class="d-flex align-center">
                <AppIcon
                  :app-name="app.icon"
                  class="mr-3"
                />
                <span>{{ app.applicationDesc }}</span>
              </div>
            </v-col>
            <v-col class="col-4 col-md-3 pr-0">
              <Pill
                v-bind="determinePillBindings(app.tasks)"
              />
            </v-col>
          </v-row>
          <v-list-item-action>
            <v-btn
              icon
              ripple
              :aria-label="'select ' + app.applicationDesc"
            >
              <v-icon class="msa-grey">
                mdi-chevron-right
              </v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>
  </div>
</template>

<script>
import Pill from "@/components/Pill";
import colours from "@/settings/doe-colors";
import { AppIcon } from "@nswdoe/doe-ui-core";
import { determineAccessLabel } from "@/utils/apps";

export default {
  name: "AppAccessList",
  components: {
    Pill,
    AppIcon
  },
  props: {
    apps: {
      type: Array,
      required: true
    },
    appHeading: {
      type: String,
      default: ""
    },
    accessHeading: {
      type: String,
      default: "Access"
    }
  },
  data() {
    return {};
  },
  methods: {
    navigateToApplication(app) {
      this.$emit("appSelected", app);
    },
    determinePillBindings(tasks) {
      const label = determineAccessLabel(tasks).toUpperCase();

      const attributeMap = {
        PARTIAL: { color: colours.white, background: colours.blue2, label },
        FULL: { color: colours.white, background: colours.blue1, label },
        PENDING: { color: colours.white, background: colours.warningOrange, label },
        default: { color: colours.blue1, background: colours.light20, label }
      };

      return attributeMap[label] || attributeMap.default;
    }
  }
}
</script>

<style lang="scss" scoped>
.list-header {
  font-weight: 600;
  background-color: #CFD8DC;
}

.theme--dark {
  .list-header {
    background-color: #263238;
  }
}

::v-deep {
  .v-list {
    padding: 0;
  }

  .v-list-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  .theme--dark {
    .v-list-item {
      border-bottom: 1px solid #6e6e6e;
    }
  }
}
</style>
