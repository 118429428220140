<template>
  <div v-if="$vuetify.breakpoint.mdAndUp">
    <v-btn
      class="desktop-back-button no-shadow font-weight-regular ml-0 pl-0"
      text
      @click="$router.go(-1)"
      aria-label="go back"
      id="app--desktop-back-button"
      :ripple="false"
    >
      <v-icon>mdi-arrow-left</v-icon>
      <span class="ml-2">Back</span>
    </v-btn>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
  #app--desktop-back-button:hover::before {
    background-color: transparent;
  }
</style>
